import React from "react";
import { Club, Colors, Match, paths} from "@monorepo/shared";
import { Box, Button,  Fade, FormControl, Grid2, InputAdornment, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, Paper, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";

import 'dayjs/locale/sv'
import { collection, deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useClubState } from "clubStateContext";

export const style = {
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -30%)',
  boxShadow: 24,
  padding: "3%",  
  width: "60vw",
};

export const Settings = ({open, onClose} : {open: boolean, onClose: () => void}) => {

    const {club} = useClubState();
    const [settings, setSettings] = React.useState<Club["settings"] | null>(null);



    React.useEffect(() => {
        if (!club) return 
        setSettings(club.settings)
    }, [club])

    const updateSettings = async (field: keyof Club['settings'], value: number) => {
      const updatedSettings = {...settings}
      updatedSettings[field] = value;

      setSettings(updatedSettings);
    }

    const saveSettings = async () => {
      const clubRef = club? doc(db, paths.clubs, club?.id!) : null;
      if (!club || !clubRef) {
        return;
      }
  
      await updateDoc(clubRef, {settings});
  }
    const inputWithLabel = (field: keyof Club['settings'], label: string, adornment = "") => (
        <TextField 
            variant="standard" 
            onChange={e => updateSettings(field, parseInt(e.target.value) || 0)}
            fullWidth 
            label={label}
            value={settings? settings[field] : 0}           
            slotProps={{
                input: {
                endAdornment: <InputAdornment position="start">{adornment}</InputAdornment>,
                },
            }}
        >
        </TextField>  
    )

  return (
    <Modal disableAutoFocus={true} closeAfterTransition open={open} onClose={onClose} >
        <Fade in={open}>
          <Paper sx={style} >
              <Box>
                <Grid2 container spacing={1} rowSpacing={4}>
                  <Grid2 size={16}>
                    <Typography variant="h5" component="div" sx={{flexGrow: 1}}>
                        <span >Uppdatera Klubbinställningar</span>
                    </Typography>
                  </Grid2> 
                  <Grid2 size={{md: 12, xs: 12}} padding={2} >
                    <Paper sx={{padding: 3}} elevation={10}>
                        <Stack spacing={2} >
                            <Stack>
                                <Typography variant="h5" component="div" sx={{flexGrow: 1}}>
                                    <span style={{fontWeight: "bold", color: Colors.primary}}>Ändra Match Ersättning </span>
                                </Typography>
                                <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                    <span style={{color: Colors.darkBlue}}>Ändra ersättning för olika typer av matcher</span>
                                </Typography>

                            </Stack>
                            {inputWithLabel("4x3", "Ungdom 4 x 3 min 3-3 (6-7 år):", "kr")}         
                            {inputWithLabel("3x15", "Ungdom 3 x 15 min 5-5 (8-9 år):", "kr")}         
                            {inputWithLabel("3x20", "Ungdom 3 x 20 min 7-7 (10-12 år):", "kr")}         
                            {inputWithLabel("3x25", "Ungdom 3 x 25 min 9-9 (13-14 år):", "kr")}         
                            {inputWithLabel("2x40", "Ungdom 2 x 40 min 11-11 (15-16 år):", "kr")}                            
                        </Stack>
                    </Paper>
                  </Grid2>
                  <Grid2 size={{md: 12, xs: 12}} padding={2}>
                    <Paper sx={{padding: 3}} elevation={10}>
                        <Stack spacing={2} >
                            <Typography variant="h5" component="div" sx={{flexGrow: 1}}>
                                <span style={{fontWeight: "bold", color: Colors.primary}}>Ändra övriga klubb inställningar </span>
                            </Typography>
                            <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                                <span style={{color: Colors.darkBlue}}>Ändrar Antalet domare som dömer på en match</span>
                            </Typography>
                            {inputWithLabel("referees", "Antal domare")}                                   
                        </Stack>
                    </Paper>
                  </Grid2>
                </Grid2>
              </Box>
              <Stack direction={"row"} justifyContent={"flex-end"}>
                <Button onClick={() => {setSettings(club!.settings); onClose()}}>Avbryt</Button>
                <Button onClick={() => {saveSettings(); onClose()}}>Spara</Button>
              </Stack>
          </Paper>
        </Fade>
    </Modal>
  )
}