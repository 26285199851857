import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button, Box, Typography, Stack, IconButton, Toolbar, Divider, Link, Chip, Tooltip, Drawer, Tabs, List, Tab, ListItemButton, ListItem, ListItemText, ListItemIcon, LinearProgress, CircularProgress } from '@mui/material';
import { useClubState } from '../../clubStateContext';
import { Club, Colors, emptyMatch, filterComing, filterPlayed, formatTime, Invitation, Match, MatchInvite, matchType, paths, Referee, refereeStatus, sortByDate } from '@monorepo/shared';
import { MatchEditor, refereeChip } from './MatchEditor';
import { useOutletContext } from 'react-router-dom';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { db } from 'config/firebase';
import { collection, doc, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import { flushSync } from 'react-dom';



const ScheduleTab = ({value, setValue, newMatch, generateExamples}: {value: number, setValue: (val: number) => void, newMatch: () => void, generateExamples: JSX.Element}) => {

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  }

  return (
    <List>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={handleChange}
        textColor='secondary'
        indicatorColor='secondary'
      >
        <Tab label="Kommande"  sx={{color: Colors.darkBlue}}/>
        <Tab label="Spelade"  sx={{color: Colors.darkBlue}}/>
        <Tab sx={{display: "none", color: Colors.darkBlue}} label="Överblick"/>
      </Tabs>
      <Divider />
      {value !== 2 && 
        <ListItem>
          <ListItemButton component="button" onClick={newMatch}>
            <ListItemIcon><AddRoundedIcon color="secondary"/></ListItemIcon>
            <ListItemText primary="Ny Match" sx={{textAlign: "center", color: Colors.white}} />
          </ListItemButton>   
        </ListItem>
      }
      {value === 0 && 
        generateExamples      
      }

    </List>
  )
}

const generateCompensation = (type: number, settings: Club["settings"]) => {
  if (type === 5) return settings['3x15'] || 150;
  if (type === 7) return settings['3x20'] || 345;
  if (type === 9) return settings['3x25'] || 430;
  return settings['2x40'] || 520;
}

const generateReferee = (referees: Invitation[], refereeStats: {[id: string]: number} , match: Match) => {
  const refereesWithPermission: Invitation[] = referees.filter((referee) => (referee.permissions || []).includes(matchType(match)))
  if (refereesWithPermission.length <= 0) {
    return null
  }  
  const referee = refereesWithPermission
    .reduce((acc, referee) => ((referee.matches || 0) + refereeStats[referee.id!] < (acc.matches || 0) + refereeStats[acc.id!] ? referee : acc));

  return referee;
}

const generateExampleMatches = (matches: Match[], referees: Invitation[], settings: Club["settings"]) => {
  const refereeStats = Object.fromEntries(referees.map(ref => [ref.id!, 0]));
  const newExamples: Match[] = [];
  const updatedMatches = matches.map(match => {
    if (!matchType(match)) {
      return match;
    }

    const newInvites = Object.entries({ ...match.invites }).filter(([id, invite]) => (invite.status !== 'invite' && invite.status !== 'declined'));
    let newInvitesLength = newInvites.length;
    newInvites.forEach(([id, invite]) => {
      refereeStats[id] += 1;
    });
    if ((match.referees || settings.referees || 1) - newInvites.length <= 0) {
      console.log('No additional referees needed for match:', match.id!);
      return match;
    }

    while ((match.referees || settings.referees || 1) - newInvitesLength > 0) {
      const referee = generateReferee(referees, refereeStats, match);
      if (referee) {
        refereeStats[referee.id!] += 1;
        newInvites.push([referee.id!, {name: referee.name, phoneNumber: referee.phoneNumber, status: "invite"}]);
      }
      newInvitesLength += 1;
    }
    const editedMatch = {...match, compensation: generateCompensation(matchType(match), settings!), "invites": Object.fromEntries(newInvites)};
    newExamples.push(editedMatch);
    return (editedMatch)
  })

  return [newExamples, updatedMatches]
};

const saveMatches = async (examples: Match[], setLoading: (val: boolean) => void, setExamples: (val: Match[]) => void, setStatusMessage: ({text, error} : {text: string, error: boolean}) => void, club?: Club) => {
  if (!club || examples.length <= 0) {
    return;
  }
  setLoading(true); 
  const clubRef = doc(db, paths.clubs, club.id!);
  const matchesRef = collection(clubRef, paths.matches);
  
  try {
    const batch = writeBatch(db);  

    examples.forEach(match => {
      if (match) {
        const matchRef = match.id ? doc(matchesRef, match.id.toString()) : doc(matchesRef);
        
        batch.set(matchRef, match, { merge: true });
        
        const overwriteData = {
          invites: match.invites || {},
        };

        batch.update(matchRef, overwriteData);
      }
    });

    await batch.commit();
    console.log("Everything completed");
    setStatusMessage({text: "Exempel sparade!", error: false});
  } catch (error) {
    setStatusMessage({text: "Misslyckades med att spara exempel", error: true});
    console.error("Error saving matches:", error);
  } finally {
    setLoading(false);
    setExamples([]);
  }
};

export const Schedule = () => {
  const {club, matches, referees} = useClubState();
  const [examples, setExamples] = React.useState<Match[]>([]);
  const [showGenerated, setShowGenerated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {setTitle, setTabSettings, setStatusMessage} = useOutletContext<{ 
    setTitle: React.Dispatch<React.SetStateAction<string>>, 
    setTabSettings: React.Dispatch<React.SetStateAction<React.ReactNode>>,
    setStatusMessage: React.Dispatch<React.SetStateAction<{
      text: string;
      error: boolean;
    }>>
  }>();

  const [coming, setComing] = useState<Match[]>([]);
  const [comingExamples, setComingExamples] = useState<Match[]>([]);

  const [played, setPlayed] = useState<Match[]>([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [selectedMatch, setSelectedMatch] = useState<Match | undefined>(undefined);

  const formatMatches = React.useCallback(() => {
    const upcomingMatches = matches.filter(filterComing).sort(sortByDate);
    const playedMatches = matches.filter(filterPlayed).sort(sortByDate).reverse();
  
    setComing(upcomingMatches);
    setPlayed(playedMatches);
  }, [matches])

const handleGenerateClick = React.useCallback(() => {
  flushSync(() => setLoading(true));

  const [newExamples, updatedMatches] = generateExampleMatches(coming, referees, club?.settings!);
  
  flushSync(() => {
    setComingExamples(updatedMatches);
    setExamples(newExamples);
    setLoading(false);  
    setShowGenerated(true); 
  });
}, [coming, referees, club?.settings]);

  const generateExampleButton = React.useCallback(() => (
    loading ? (
      <ListItem sx={{ width: '100%', justifyContent: "center", alignContent: "center"}}>
        <Box sx={{ width: '50%', paddingTop: "20px" }}>
          <LinearProgress sx={{ height: 4 }} /> 
        </Box>
      </ListItem>
    ) : (
      !showGenerated ? (
        <ListItem>
          <ListItemButton component="button" onClick={handleGenerateClick}>
            <ListItemIcon>
              <AutorenewIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Generera exempel" sx={{ textAlign: "center", color: Colors.white }} />
          </ListItemButton>
        </ListItem>
      ) : (
        <div>
          <ListItem>
            <ListItemButton component="button" onClick={() => setShowGenerated(false)}>
              <ListItemText primary="Avbryt" sx={{ textAlign: "center", color: Colors.white }} />
            </ListItemButton>
            <ListItemButton component="button" onClick={() => { 
              setLoading(true); 
              saveMatches(examples, setLoading, setExamples, setStatusMessage, club); 
              setShowGenerated(false);
            }}>
              <ListItemText primary="Spara" sx={{ textAlign: "center", color: Colors.white }} />
            </ListItemButton>
          </ListItem>
        </div>
      )
    )
  ), [loading, showGenerated, handleGenerateClick, examples, club, setStatusMessage]);

  useEffect(() => {
    if (club) setTitle("Kommande Matcher");
    setTabSettings(<ScheduleTab generateExamples={generateExampleButton()} newMatch={() => setSelectedMatch(emptyMatch())} value={currentTab} setValue={setCurrentTab}/>); 
  }, [setTitle, setTabSettings, currentTab, club, examples, generateExampleButton]);

  useEffect(() => {
    let title = "Kommande Matcher";
    if (currentTab === 1) title = "Spelade Matcher";
    if (currentTab === 2) title = "Match Överblick";
    setTitle(title);
    console.log(!club)
    if (!club) {
      setTitle("Saknar tillgång till klubb");
    }
  }, [currentTab, setTitle, club])



  useEffect(() => {
    formatMatches();
  }, [matches, formatMatches]);


  const displayInvites = (invites?: MatchInvite[]) => {
    if (!invites || invites.length < 0) {
      return null;
    }

    return (
      <Stack spacing={0.2}>
        {invites.slice(0, 1).map(invite => (
          refereeChip(invite, invites.length - 1, currentTab === 0)
        ))}
      </Stack>
    )
  }

  return (
    <Paper sx={{ overflow: "hidden", height: "100%" }} elevation={24}>
      <MatchEditor referees={referees} clubId={club?.id!.toString() || ""} open={selectedMatch !== undefined} match={selectedMatch || emptyMatch()} onClose={() => setSelectedMatch(undefined)} />
      <TableContainer sx={{ height: "100%"}} component={Paper}>
        <Table stickyHeader size="small">
          <TableHead sx={{width: "100%"}}>
            <TableRow>
              {["", "Tid", "Plats", "Lag", "Division", "Domare"].map(title => (
                <TableCell key={title} sx={{ whiteSpace: 'nowrap', bgcolor: Colors.lightGrey}}><span style={{fontWeight: "bolder"}}>{title}</span></TableCell>
              ))}
              <TableCell sx={{ whiteSpace: 'nowrap', bgcolor: Colors.lightGrey}} align="right"><span style={{fontWeight: "bolder"}}>Ersättning (per domare)</span></TableCell>
            </TableRow>
          </TableHead>  
          <TableBody >
            {(currentTab === 0 ? (showGenerated ? comingExamples : coming) : played).map((match, i) => (
              <TableRow  key={i} hover onClick={() => { setSelectedMatch(match) }} sx={{ cursor: 'pointer' }}>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{i}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{formatTime(match.time)}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.place}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  <Stack direction="column">
                    <span>{match.home}</span>
                    <span>{match.away}</span>
                  </Stack>
                </TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{match.division}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }}>{displayInvites(Object.values(match.invites || {}))}</TableCell>
                <TableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                  {match.compensation ? match.compensation + "  kr" : ""}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};