import React from "react";
import { Colors, emptyMatch, filterComing, Invitation, Match, MatchInvite, paths, Referee, refereeStatus } from "@monorepo/shared";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fade, FormControl, Grid2, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Modal, Paper, Select, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MenuIcon from '@mui/icons-material/Menu';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import ClearIcon from '@mui/icons-material/Clear';
import 'dayjs/locale/sv'
import dayjs, { Dayjs,  } from 'dayjs';
import { TimePicker } from "@mui/x-date-pickers";
import { collection, deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useOutletContext } from "react-router-dom";

export const style = {
  position: 'absolute' as 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -30%)',
  boxShadow: 24,
  padding: "3%",  
};

const statusColor = (status: refereeStatus, tab: boolean) => {
  switch (status) {
    case "invite": return tab ? "default" : "warning";
    case "accepted": return tab ? "success" : "default";
    case "done": return tab ? "warning" : "info"; 
    case "paid": return tab ? "warning" : "success"; 
    default: return "error";
  }
}

export const refereeIcon = (status: refereeStatus, tab: boolean) => {
  let color:  "default" | "warning" | "success" | "info" | "error" = statusColor(status, tab);

  switch (status) {
    case "invite": return <EmailIcon color={color !== "default" ? color : "inherit"}/>;
    case "accepted": return tab ? <MarkEmailReadIcon color={color !== "default" ? color : "inherit"}/> : <HourglassBottomRoundedIcon color={color !== "default" ? color : "inherit"}/>;
    case "done": return <AttachMoneyIcon color={color !== "default" ? color : "inherit"}/>; 
    case "paid": return <PriceCheckIcon color={color !== "default" ? color : "inherit"}/>; 
    case "declined": return <ClearIcon color={color !== "default" ? color : "inherit"}/>;
  }
}
const statusToTile = (status: refereeStatus, tab: boolean) => {
  switch (status) {
    case "invite" : return "Inbjuden";
    case "accepted": return tab ? "Accepterad" : "Ej Attesterad";
    case "done": return "Attesterad";
    case "paid": return "Betald";
    case "declined": return "Nekad";
    default: return "Ej inbjuden";
  }
}
export const refereeChip = (invite: MatchInvite, amount: number, matchTab: boolean) => {

  return (
    <Tooltip title={statusToTile(invite.status, matchTab)} key={invite.name}>
      <Chip 
        sx={{justifyContent: "space-between", opacity: 0.6, flexDirection: "row-reverse", width: "100%", paddingRight: 1}} 
        color={statusColor(invite.status, matchTab)} size="small"  
        icon={refereeIcon(invite.status, matchTab)} label={`${invite.name} ${amount ? "+" + amount : "" }`}
      />
    </Tooltip>
  )
}

export const MatchEditor = ({referees, match, open, onClose, clubId}: {referees: Invitation[], match: Match, open: boolean, onClose: () => void, clubId: string}) => {
  const [updatedMatch, setUpdatedMatch] = React.useState(match);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [refereeSearch, setRefereeSearch] = React.useState("");

  const {setStatusMessage} = useOutletContext<{setStatusMessage: React.Dispatch<React.SetStateAction<{
    text: string;
    error: boolean;
  }>>}>();


  React.useEffect(() => {
    setUpdatedMatch(match);
    setOpenDelete(false);
  }, [match, setUpdatedMatch])

  const handleInputChange = (value: string | number | {[k: string]: MatchInvite}, field: keyof Match) => {
    setUpdatedMatch(prev => ({...prev!, [field] : value}))
  };

  const updateReferee = (referee: Invitation, status: refereeStatus | null) => {
    const newInvites = {...updatedMatch.invites}
    if (!referee.id) return
    if (newInvites[referee.id]) {
      delete newInvites[referee.id];
    }
    const statusIndices: (refereeStatus | '')[] = filterComing(updatedMatch) ? ['', 'invite'] : ['', 'done'];
    const statusIndex = statusIndices.indexOf(status ? status : '') + 1;
    const nextStatus = statusIndices[statusIndex % statusIndices.length]

    if (nextStatus) {
      newInvites[referee.id] = {
        name: referee.name,
        phoneNumber: referee.phoneNumber,
        status: nextStatus,
      }
    }

    handleInputChange(newInvites, 'invites');
};

  const saveMatch = async () => {
    if (!clubId) return;
    try {
      const clubRef = doc(db, paths.clubs, clubId);
      const savedMatch = {...updatedMatch}
      let matchRef;
      if (!updatedMatch.id) {
        matchRef = doc(collection(clubRef, paths.matches));
        savedMatch.id = matchRef.id;
        savedMatch.clubId = clubId;
      } else {
        matchRef = doc(clubRef, paths.matches, updatedMatch.id.toString());
      }
    
      await setDoc(matchRef, savedMatch);
      setStatusMessage({text: "Match sparad!", error: false});
    } catch {
      setStatusMessage({text: "Misslyckades att spara match!", error: true});
    } finally {
      onClose();
    }
  }

  const removeMatch = async () => {
    if (clubId && updatedMatch.id) {
      try {
        const clubRef = doc(db, paths.clubs, clubId);
        const matchRef = doc(clubRef, paths.matches, updatedMatch.id.toString());
        deleteDoc(matchRef)        
        setStatusMessage({text: "Match borttagen!", error: false});
      } catch {
        setStatusMessage({text: "Misslyckades med att ta bort match!", error: true});
      } finally {
        onClose();
      }
    }
    onClose()
  }

  return (
    <Modal disableAutoFocus={true} closeAfterTransition open={open} onClose={onClose}>
        <Fade in={open}>
          <Paper sx={style} >
            <Stack sx={{flexDirection: {xs: "column", md: "row"}}} width={"100%"}>
              <Box>
                <Grid2 container spacing={2} rowSpacing={4}>
                  <Grid2 size={16}>
                  <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                    <span >{!match.id ? "Ny match" : "Uppdatera Match"}</span>
                  </Typography>          
                  </Grid2>  
                  <Grid2 size={6}>
                    <TextField fullWidth label="Hemmalag" value={updatedMatch.home} onChange={(e) => handleInputChange(e.target.value, "home")}></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField fullWidth label="Bortalag" value={updatedMatch.away} onChange={(e) => handleInputChange(e.target.value, "away")}></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField fullWidth label="Division" value={updatedMatch.division} onChange={(e) => handleInputChange(e.target.value, "division")}></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                    <TextField fullWidth label="Plats" value={updatedMatch.place} onChange={(e) => handleInputChange(e.target.value, "place")}></TextField>
                  </Grid2>
                  <Grid2 size={6}>
                      <DatePicker
                        sx={{width: "100%"}}
                        label="Datum"
                        value={updatedMatch.time ? dayjs(updatedMatch.time) : null}
                        onChange={(value) => handleInputChange(value?.toISOString()!, "time")}
                        />
                  </Grid2>
                  <Grid2 size={6}>
                    <TimePicker
                      sx={{width: "100%"}}
                      label="Tid"
                      onChange={(value) => handleInputChange(value?.toISOString()!, "time")}
                      value={updatedMatch.time ? dayjs(updatedMatch.time) : null}
                      />
                  </Grid2>
                  <Grid2 size={4}>
                    <TextField fullWidth label="Kompensation (kr)" value={updatedMatch.compensation || 0} type="number" inputMode="numeric" onChange={(e) => handleInputChange(parseInt(e.target.value) >= 0 ? e.target.value : 0, "compensation")}></TextField>
                  </Grid2>                  
                  <Grid2 size={4}>
                    <TextField  fullWidth label="Antal domare" value={updatedMatch.referees || 1} type="number" onChange={(e) => handleInputChange(parseInt(e.target.value) >= 1 ? e.target.value : 1, "referees")}></TextField>
                  </Grid2>
                  <Grid2 size={4}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel id="matchType-label">Match typ</InputLabel>
                      <Select
                        fullWidth
                        id="matchType"
                        value={updatedMatch.periodLength || 0}
                        label="Matchtyp"
                        placeholder="Välj match typ"
                        labelId="matchType-label"
                        onChange={(e) => handleInputChange(e.target.value, "periodLength")}
                        >
                        <MenuItem value={180}>3-3</MenuItem>
                        <MenuItem value={900}>5-5</MenuItem>
                        <MenuItem value={1200}>7-7</MenuItem>
                        <MenuItem value={1500}>9-9</MenuItem>
                        <MenuItem value={2400}>11-11</MenuItem>
                      </Select>  
                    </FormControl>                
                  </Grid2>
                </Grid2>

                <Stack alignSelf={"center"} justifyContent={"space-between"} spacing={3} direction={"row"} marginTop={2}>
                  <Dialog
                    open={openDelete}
                    onClose={() => setOpenDelete(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Radera Match"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Tar bort en match, går inte att ångra. 
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpenDelete(false)} autoFocus>Avbryt</Button>
                      <Button onClick={removeMatch}> Radera </Button>
                    </DialogActions>
                  </Dialog>
                  <Button variant="contained" color="error" disabled={!updatedMatch.id} onClick={() => setOpenDelete(true)}>
                    Radera
                  </Button>
                  <Stack alignSelf={"center"} justifyContent={"center"} spacing={3} direction={"row"} margin={2}>
                    <Button variant="contained" onClick={onClose}>
                      Avbryt
                    </Button>
                    <Button variant="contained" onClick={saveMatch}>
                      Spara
                    </Button>
                  </Stack>
                </Stack>
              </Box>
              <Divider orientation="vertical" flexItem sx={{margin: 2, bgcolor: Colors.primary}}/>
              <Box width="100%">
                <TextField variant="standard" label="Domare" fullWidth onChange={(e) => setRefereeSearch(e.target.value)} value={refereeSearch}></TextField>
                <List sx={{overflowY: "auto", maxHeight: {xs: "250px", md: "400px"}}}>
                  {referees.filter(referee => referee.name.toLocaleLowerCase().includes(refereeSearch.toLocaleLowerCase())).map(referee => {
                    const defaultReferee = updatedMatch.invites? updatedMatch.invites[referee.id!] : null;
                    return (
                      <ListItem
                        divider 
                        key={referee.id}
                        onPointerDown={() => updateReferee(referee, defaultReferee ? defaultReferee.status : null)} 
                      >
                        <Tooltip placement="right" title={statusToTile(defaultReferee?.status || null, filterComing(match))} key={referee.name}>

                          <Stack direction={"row"} width={"100%"} sx={{justifyContent: "space-between", flexDirection: 1}} alignItems={"center"} >
                            <ListItemText>{referee?.name || ""}</ListItemText>
                            <ListItemIcon sx={{justifyContent: "flex-end"}}>{defaultReferee ? refereeIcon(defaultReferee.status, filterComing(updatedMatch)) : ""}</ListItemIcon>
                          </Stack>
                        </Tooltip>
                      </ListItem>
                    )
                  })} 
                </List>
              </Box>
            </Stack>

          </Paper>
        </Fade>
    </Modal>
  )
}