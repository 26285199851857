import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { Routes, Route, Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Tabs, Tab, Box, Toolbar, IconButton, Menu, MenuItem, Drawer, Paper, Avatar, Slide, SlideProps, Snackbar, Alert, Button, ButtonBase } from '@mui/material';
import { auth, db } from 'config/firebase';
import { collection, doc, onSnapshot, query, where, getDocs, getDoc, DocumentData, Query, DocumentReference } from 'firebase/firestore';
import { User as FBUser, signOut } from 'firebase/auth';
import { Club, Colors, Invitation, Match, User, paths } from '@monorepo/shared';
import { useClubState } from '../../clubStateContext';
import { AccountCircle } from '@mui/icons-material';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TrainerSchedule } from './TrainerSchedule';
import { Settings } from './Settings';

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const drawerWidth = 300;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: Colors.lightGrey,
  height: '100vh',  // Ensure it takes the full height of the viewport
  overflow: 'hidden',  // Prevent parent from scrolling
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: !open ? 0 : `${drawerWidth}px`,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Home = () => {
  const location = useLocation();
  const {club, user, userInvitation} = useClubState();

  const [value, setValue] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const [tabSettings, setTabSettings] = useState<React.ReactNode>(null);

  const [showSettings, setShowSettings] = React.useState(false);

  const [statusMessage, setStatusMessage] = React.useState({text: "", error: false})

  const navigate = useNavigate();


  React.useEffect(() => {
    const startingTab = () => {
      switch(location.pathname) {
        case ("/home/payments"): return 2;
        case ("/home/referees"): return 1;
        default: return 0;
      }
    }
    setValue(startingTab())
  }, [location.pathname, setValue])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate("schedule");
        break;
      case 1:
        navigate("referees");
        break;
        case 2:
        navigate("payments");
        break;
      default:
        break;
    }
  };


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"sv"}>
      <Box sx={{ flex:1, width: '100%', height: "100vh", padding: 0, bgcolor: Colors.primary }}>
        <AppBar position="fixed" open={open}>
          <Toolbar >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerOpen}
              sx={[open && { display: 'none' }]}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{paddingLeft: 2, flexGrow: 1}}>
              {title}
            </Typography>
            <Typography noWrap component="div" sx={{paddingLeft: 2, flexShrink: 1, display: {xs:  'none',sm: 'block',}}}>
              {club?.clubName || ""}
            </Typography>  

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <Settings open={showSettings} onClose={() => setShowSettings(false)}></Settings>
                <MenuItem><AccountCircle /> <Typography paddingLeft={1}>{user?.name || ""}</Typography></MenuItem>
                <MenuItem onClick={() => setShowSettings(true)}>Klubbinställningar</MenuItem>
                <MenuItem onClick={() => signOut(auth)}>Logga ut</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Snackbar TransitionComponent={SlideTransition} open={statusMessage.text !== ""} autoHideDuration={4000} onClose={() => setStatusMessage({text: "", error: false})}>
          <Alert
            onClose={() => setStatusMessage({text: "", error: false})}
            severity={statusMessage.error ? "error" : "success"}
            variant="filled"
            sx={{ width: '100%'}}
          >
            {statusMessage.text}
          </Alert>
        </Snackbar>
        <Main open={open}>
          <Toolbar />
          <Box sx={{ bgcolor: Colors.lightGrey, maxHeight: "calc(100vh - 64px)", padding: {md: 2, xs: 0} }}>
          {
              (userInvitation?.role !== "trainer" && club ? 
                <Outlet context={{ setTitle, setTabSettings, setStatusMessage }}/> 
              :
                <TrainerSchedule setTitle={setTitle} setTabSettings={setTabSettings}/>)
            }
          </Box>
        </Main>
        <Drawer
          elevation={24}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              backgroundColor: Colors.primary, 
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader sx={{justifyContent: "space-between"}}>
            <ButtonBase onClick={() => {navigate("/")}}>
              <Avatar src={require("../landingPage/images/icon.png")}>
              </Avatar>
              <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
                <span style={{color: Colors.white, fontStyle: "italic"}}>REFFI</span>
              </Typography>            
            </ButtonBase>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon color="secondary"/> 
            </IconButton>
          </DrawerHeader>
          <Divider />
          {userInvitation?.role !== "trainer" && <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            textColor='secondary'
            indicatorColor='secondary'
          >
            <Tab label="Schema"  sx={{color: Colors.darkBlue}}/>
            <Tab label="Domare"  sx={{color: Colors.darkBlue}}/>
            <Tab label="Betalningar" sx={{color: Colors.darkBlue}}/>
          </Tabs>}
          <Divider />
          {tabSettings && <div>{tabSettings}</div>}       
        </Drawer>
      </Box>
    </LocalizationProvider>

  );
};

export default Home;
