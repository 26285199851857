import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Container, CssBaseline, Tab, Tabs } from '@mui/material';
import { LandingPage } from './components/landingPage/LandingPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { auth, db } from 'config/firebase';
import { signOut, User } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Colors, paths } from '@monorepo/shared';
import Home from 'components/home/Home';
import { Schedule } from 'components/home/Schedule';
import { Referees } from 'components/home/Referees';
import { Payments } from 'components/home/Payments';
import { ClubStateProvider } from 'clubStateContext';
import { GlobalStyles } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#5D97E5', // Change the primary color
      contrastText: '#FFF'
    },
    secondary: {
      contrastText: '#FFF',
      main: '#FFF', // Change the secondary color
    },
  },
});

const App = () => {
  const [user, setUser] = React.useState<User | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [isClubUser, setIsClubUser] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false); 
    });
    return () => unsubscribe();
  }, []);

  React.useEffect(() => {
    if (!location.pathname.includes("home")) {
      return;
    }
    if (!loading && user) {
      const checkClubUser = async () => {
        try {
          // Check for the club user document
          const clubUserDoc = await getDoc(doc(db, paths.clubUsers, user.uid));
          if (clubUserDoc.exists()) {
            setIsClubUser(true);
          } else {
            console.log("Club user not found, retrying in 1 second...");
            setTimeout(checkClubUser, 1000); // Retry after 1 second
          }
        } catch (error) {
          console.error('Error checking user type:', error);
          await signOut(auth);
          navigate('/');
        }
      };
  
      checkClubUser();
    } else if (!loading && !user) {
      navigate('/');
    }
  }, [loading, user, navigate, location.pathname]);

  React.useEffect(() => {
    if (user && isClubUser && location.pathname === "/auth") {
      navigate('/home/schedule'); 
    }
  }, [isClubUser, location.pathname, user, navigate]);


  return (
  <ClubStateProvider userId={user ? user.uid : ""}>
    <GlobalStyles
      styles={{
        "::-webkit-scrollbar": {
          width: "8px",
          height: "8px",
        },
        "::-webkit-scrollbar-track": {
          background: Colors.white,
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: Colors.primary,
        },
        "::-webkit-scrollbar-thumb:hover": {
          backgroundColor: Colors.darkBlue,
        },
        backgroundColor: Colors.primary,
      }}
    />    
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/auth" element={<LandingPage />}/>
        <Route path="/home/" element={<Home />}>
          <Route index element={<Navigate to="schedule" replace />} />
          <Route path="*" element={<Navigate to="schedule" />} />
          <Route path="schedule" element={<Schedule />} />
          <Route path="referees" element={<Referees />} />
          <Route path="payments" element={<Payments />} />
        </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </ClubStateProvider>
  );
};

export default function Root() {
  return (
    <Router basename='/'>
      <ThemeProvider theme={theme}>
        <CssBaseline /> 
        <App />
      </ThemeProvider>
    </Router>
  );
}
