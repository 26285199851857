export var Colors = {
    primary: '#5D97E5',
    lightBlue: '#B3E4FF',
    lightestBlue: '#D9F2FF',
    white: '#FFF',
    lightGrey: '#EAEAEE',
    darkBlue: "#3A6BAB",
    black: '#000',
    accept: "#72BB53",
    deny: "#F85F6A",
    warning: "#FFB302",
};
export var paths = {
    clubUsers: "clubUsers",
    users: "users",
    clubs: "clubs",
    invitations: "invitations",
    referees: "referees",
    matches: "matches",
    invites: "invites",
    activeClubs: "clubsPublic",
    messages: "messages",
};
export var emptyMatch = function () {
    return {
        time: "",
        place: "",
        division: "",
        home: "",
        away: "",
        compensation: 0,
        referees: 0,
        invites: {},
        id: undefined,
        clubId: undefined,
        periodLength: 0,
    };
};
